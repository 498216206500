<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.usuarios.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.usuarios.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.usuarios.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <xlsx-workbook v-if="excel && excel.length > 0 && excel[0].data.length > 0">
                  <xlsx-sheet
                    v-for="sheet in excel"
                    :key="sheet.name"
                    :collection="sheet.data"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download
                    :filename="excelName"
                  >
                    <v-btn
                      text
                      :disabled="excel.length == 0"
                    >
                      <v-icon>
                        mdi-file-excel-box
                      </v-icon>
                      &nbsp;
                      Exportar a Excel
                    </v-btn>
                  </xlsx-download>
                </xlsx-workbook>
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  v-model="filters.selected.items"
                  :items="filters.data.roles"
                  item-text="name"
                  item-value="id"
                  :label="$t('app.roles.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.roles.name="{ item }">
              <template v-if="item.roles.length > 0">
                <v-chip
                  v-for="(role, index) in item.roles"
                  :key="`role-${index}`"
                  label
                  small
                >
                  {{ role.name }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.sucursales.nombre="{ item }">
              <template v-if="item.sucursales.length > 0">
                <v-chip
                  v-for="(sucursal, index) in item.sucursales"
                  :key="`sucursal-${index}`"
                  label
                  small
                >
                  {{ sucursal.nombre }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.avatar="{ item }">
              <v-col class="d-flex justify-center">
                <v-img
                  :src="item.avatar"
                  :lazy-src="preloader"
                  width="60"
                />
              </v-col>
            </template>
            <template v-slot:item.direccion="{ item }">
              {{
                [
                  item.persona.calle,
                  item.persona.colonia,
                  item.persona.localidad.nombre,
                  item.persona.municipio.nombre,
                  item.persona.estado.nombre
                ].join(', ')
              }}
            </template>
            <template v-slot:item.telefonos="{ item }">
              {{ item.persona.telefonos ? item.persona.telefonos.join(', ') : '' }}
            </template>
            <template v-slot:item.persona.fecha_nacimiento="{ item }">
              {{ $moment(item.persona.fecha_nacimiento).format('MMM Do, YYYY') }}
              <br>
              <small>{{ $moment(item.persona.fecha_nacimiento).fromNow(true) }}</small>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.promotes.includes('referee') && item.promotes.includes('player')"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="!item.promotes.includes('referee')"
                      @click="openPromoteToReferee(item)"
                    >
                      <v-list-item-title>
                        {{ $t('app.buttons.promote_to') }}
                        {{ $t('app.arbitros.single') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!item.promotes.includes('player')"
                      @click="openPromoteToPlayer(item)"
                    >
                      <v-list-item-title>
                        {{ $t('app.buttons.promote_to') }}
                        {{ $t('app.jugadores.single') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <usuarios-add />
    <usuarios-edit />
    <usuarios-detail />
    <usuarios-delete />

    <jugadores-add />
    <jugadores-add-to-team-question />
    <jugadores-add-to-team />
    <jugadores-add-to-team-edit />
    <arbitros-add />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  import { mapMutations } from 'vuex'
  import latinize from 'latinize'
  import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'Usuarios',
    mixins: [customFilter],
    components: {
      UsuariosAdd: () => import('./UsuariosAdd'),
      UsuariosEdit: () => import('./UsuariosEdit'),
      UsuariosDetail: () => import('./UsuariosDetail'),
      UsuariosDelete: () => import('./UsuariosDelete'),

      JugadoresAdd: () => import('../jugadores/JugadoresAdd'),
      ArbitrosAdd: () => import('../arbitros/ArbitrosAdd'),
      JugadoresAddToTeamQuestion: () => import('../jugadores/JugadoresAddToTeamQuestion'),
      JugadoresAddToTeam: () => import('../jugadores/JugadoresAddToTeam'),
      JugadoresAddToTeamEdit: () => import('../jugadores/JugadoresAddToTeamEdit'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.roles.name'),
            value: 'roles.name',
          },
          {
            text: this.$t('app.sucursales.name'),
            value: 'sucursales.nombre',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'persona.fullname',
          },
          {
            text: this.$t('app.headers.email'),
            value: 'email',
          },
          {
            text: this.$t('app.headers.borndate'),
            value: 'persona.fecha_nacimiento',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            roles: [{
              name: this.$t('app.general.role_none'),
              id: 0,
            }],
          },
          selected: {
            items: [],
            query: '',
          },
        },
        excel: [],
        excelName: this.$t('app.usuarios.title') + ' - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelHiddenColumns: [
          'id',
        ],
      }
    },
    computed: {
      filteredItems () {
        if (this.filters.selected.items.length > 0) {
          return this.items.filter(item => {
            if (this.filters.selected.items.includes(0)) {
              return item.roles.length === 0
            }

            const roles = item.roles.filter(item => this.filters.selected.items.includes(item.id))
            return roles.length > 0
          })
        } else {
          return this.items
        }
      },
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    async mounted () {
      await this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      ...mapMutations({
        setAppData: 'SET_APP_DATA',
      }),
      async init () {
        this.toggleLoader()

        const usuarios = (await this.getUsuarios())
        this.items = usuarios.map(user => {
          if (user && user.roles && user.roles.length > 0) {
            user.promotes = user.roles.map(role => role.name)
          } else {
            user.promotes = []
          }
          return user
        })

        /*this.setAppData({
          usuarios,
        })*/

        this.filters.data.roles = [
          ...this.filters.data.roles,
          ...await this.getRoles(),
        ]

        this.excel = [
          {
            name: this.$t('app.general.report'),
            data: this.prepareForExcel(this.items, this.headers, this.excelHiddenColumns),
          },
        ]

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('usuarios-add')
      },
      openItemEdit (item) {
        EventBus.$emit('usuarios-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('usuarios-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('usuarios-delete', item)
      },
      openPromoteToPlayer (user) {
        EventBus.$emit('jugadores-add-hide-person', user)
      },
      openPromoteToReferee (user) {
        EventBus.$emit('arbitros-add', user)
      },
    },
  }
</script>

<style scoped>

</style>
